<template>
  <v-container fluid class="pa-0">
    <v-row v-if="$vuetify.breakpoint.mdAndUp" no-gutters class="pt-2 pl-8">
      <span class="font-weight-medium grey--text text--darken-4 text-h6">{{
        $t("excavationDetails")
      }}</span>
    </v-row>
    <v-row v-if="$vuetify.breakpoint.smAndDown">
      <v-card
        width="100%"
        class="rounded-lg"
        elevation="0"
        style="border: 1px solid #e0e0e0; border-radius: 8%"
      >
        <MapView />
        <v-card-actions>
          <v-row justify="center">
            <span
              class="font-weight-medium grey--text text--darken-4 text-subtitle-2 mr-2 my-2"
              >{{ $store.state.Ticket.ticketData ? streetAddress : "" }}</span
            >
            <a class="primary--text my-2 body-2" @click="$emit('editLocation')">
              <img
                src="@/assets/images/edit_location.svg"
                role="img"
                alt="edit location Logo"
              />
              <span class="mb-auto">{{ $t("editLocation") }}</span></a
            >
          </v-row>
        </v-card-actions>
        <v-card-text>
          <v-row>
            <v-col
              cols="3"
              class="py-1"
              :class="$store.state.Ticket.ticketData.county ? '' : 'my-auto'"
            >
              {{ $t("county") }} :
            </v-col>
            <v-col
              v-if="
                $store.state.Ticket.ticketData.county &&
                $store.state.Ticket.ticketData.county != ''
              "
              class="font-weight-medium grey--text text--darken-4 text-subtitle-2 py-1"
            >
              {{
                $store.state.Ticket.ticketData
                  ? $store.state.Ticket.ticketData.county
                  : ""
              }}
            </v-col>
            <v-col
              v-else
              class="font-weight-medium grey--text text--darken-4 text-subtitle-2 py-1"
            >
              <v-text-field
                v-model="county"
                outlined
                dense
                required
                hide-details
                placeholder="Enter County"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="3"
              class="py-1"
              :class="$store.state.Ticket.ticketData.place ? '' : 'my-auto'"
            >
              {{ $t("place") }} :
            </v-col>
            <v-col
              v-if="
                $store.state.Ticket.ticketData.place &&
                $store.state.Ticket.ticketData.place != ''
              "
              class="font-weight-medium grey--text text--darken-4 text-subtitle-2 py-1"
            >
              {{
                $store.state.Ticket.ticketData
                  ? $store.state.Ticket.ticketData.place
                  : ""
              }}
            </v-col>
            <v-col
              v-else
              class="font-weight-medium grey--text text--darken-4 text-subtitle-2 py-1"
            >
              <v-text-field
                v-model="place"
                outlined
                dense
                required
                hide-details
                placeholder="Enter Place"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="3"
              class="py-1"
              :class="$store.state.Ticket.ticketData.street ? '' : 'my-auto'"
            >
              {{ $t("street") }} :
            </v-col>
            <v-col
              v-if="
                $store.state.Ticket.ticketData.street &&
                $store.state.Ticket.ticketData.street != ''
              "
              class="font-weight-medium grey--text text--darken-4 text-subtitle-2 py-1"
            >
              {{ $store.state.Ticket.ticketData ? streetAddress : "" }}
            </v-col>
            <v-col
              v-else
              class="font-weight-medium grey--text text--darken-4 text-subtitle-2 py-1"
            >
              <v-text-field
                v-model="street"
                outlined
                dense
                required
                hide-details
                placeholder="Enter Street"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              :class="streetLocation.cross1 ? '' : 'my-auto'"
              cols="3"
              class="py-1"
            >
              {{ $t("cross1") }} :
            </v-col>
            <v-col
              class="font-weight-medium grey--text text--darken-4 text-subtitle-2 py-1"
            >
              <v-text-field
                v-model="cross1"
                outlined
                dense
                required
                hide-details
                placeholder="Enter Cross 1"
                :error-messages="cross1Error"
                @input="$v.cross1.$touch()"
                @blur="$v.cross1.$touch()"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              :class="streetLocation.cross2 ? '' : 'my-auto'"
              cols="3"
              class="py-1"
            >
              {{ $t("cross2") }} :
            </v-col>
            <v-col
              class="font-weight-medium grey--text text--darken-4 text-subtitle-2 py-1"
            >
              <v-text-field
                v-model="cross2"
                outlined
                dense
                required
                hide-details
                placeholder="Enter Cross 2"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-text> </v-card
    ></v-row>
    <v-row
      no-gutters
      :class="$vuetify.breakpoint.mdAndUp ? 'pl-8 pt-2' : 'mt-1 mx-5'"
    >
      <v-col>
        <v-row no-gutters class="mt-2">
          <span
            class="font-weight-medium grey--text text--darken-4 text-subtitle-1"
            >{{ $t("811Center") }}</span
          >
        </v-row>
        <v-row
          no-gutters
          :class="$vuetify.breakpoint.mdAndUp ? 'pt-5 pb-2' : 'py-3'"
        >
          <v-col>
            <img
              class="pa-4"
              style="border: 1px solid #e0e0e0; border-radius: 8%"
              src="@/assets/images/ohio-811.svg"
              role="img"
              alt="Ohio 811 Center Logo"
            />
          </v-col>
          <v-col :class="$vuetify.breakpoint.mdAndUp ? 'mt-3' : 'mt-3 ml-10'">
            <v-row no-gutters>
              <span
                class="font-weight-normal grey--text text--darken-1 text-caption"
                >{{ $t("excavatorType") }}</span
              >
            </v-row>
            <v-row no-gutters>
              <span
                class="font-weight-medium grey--text text--darken-4 text-subtitle-2"
                >{{ companyExcavatorType }}</span
              >
            </v-row>
          </v-col>
        </v-row>

        <v-row v-if="$vuetify.breakpoint.mdAndUp" class="mb-2">
          <v-divider></v-divider>
        </v-row>

        <v-row no-gutters>
          <v-col class="mt-4 mb-10">
            <AssociateTicketExcvDetails
              v-if="checkPermission('associateTicketExcavationDetails')"
              ref="associateTicket"
            />
            <v-row class="mb-2" no-gutters>
              <span
                class="font-weight-medium grey--text text--darken-4 text-subtitle-1"
                >{{ $t("tellAboutExcavation") }}</span
              >
            </v-row>
            <v-row no-gutters justify="center">
              <v-menu
                ref="menu1"
                v-model="menu1"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
                :disabled="$store.state.Ticket.isEdit == 'Digin'"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="work_date"
                    persistent-hint
                    outlined
                    dense
                    prepend-inner-icon="mdi-calendar"
                    v-bind="attrs"
                    :error-messages="dateFormattedErrors"
                    v-on="on"
                    @input="$v.work_date.$touch()"
                    @blur="$v.work_date.$touch()"
                    :disabled="$store.state.Ticket.isEdit == 'Digin'"
                  >
                    <template v-slot:label>
                      <span class="red--text"> *</span>
                      {{ $t("excavationStartDate") }}
                    </template></v-text-field
                  >
                </template>
                <v-date-picker
                  v-model="date"
                  no-title
                  :min="getNowDate"
                  :max="getEndDate"
                  :allowed-dates="allowedDates"
                  @input="menu1 = false"
                ></v-date-picker>
              </v-menu>
              <v-menu
                ref="menu"
                v-model="menu2"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="time"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
                :disabled="$store.state.Ticket.isEdit == 'Digin'"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="scheduleTime"
                    :label="$t('time')"
                    format="ampm"
                    prepend-inner-icon="mdi-clock-time-four-outline"
                    persistent-hint
                    outlined
                    dense
                    v-bind="attrs"
                    v-on="on"
                    :class="$vuetify.breakpoint.mdAndUp ? 'ml-3' : ''"
                    :error-messages="scheduleTimeError"
                    @input="$v.scheduleTime.$touch()"
                    @blur="$v.scheduleTime.$touch()"
                    @change="updateTimeFormat"
                    readonly
                    :disabled="$store.state.Ticket.isEdit == 'Digin'"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="menu2"
                  v-model="time"
                  :menu-props="{ bottom: true, offsetY: true }"
                  full-width
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu2 = false">
                    Cancel
                  </v-btn>
                  <v-btn text color="primary" @click="$refs.menu.save(time)">
                    OK
                  </v-btn>
                </v-time-picker>
              </v-menu>
            </v-row>
            <v-row no-gutters>
              <v-text-field
                v-model="contact"
                outlined
                dense
                required
                :error-messages="contactErrors"
                @input="$v.contact.$touch()"
                @blur="$v.contact.$touch()"
              >
                <template v-slot:label>
                  <span class="red--text"> *</span> Site Contact Name
                </template></v-text-field
              >
            </v-row>
            <v-row no-gutters>
              <v-text-field
                v-model="contact_phone"
                outlined
                dense
                required
                :error-messages="contactPhoneErrors"
                @input="$v.contact_phone.$touch()"
                @blur="$v.contact_phone.$touch()"
              >
                <template v-slot:label>
                  <span class="red--text"> *</span> {{ $t("siteContactPhone") }}
                </template></v-text-field
              >
            </v-row>
            <v-row no-gutters>
              <v-text-field
                v-model="done_for"
                outlined
                dense
                required
                :error-messages="workDoneForErrors"
                @input="$v.done_for.$touch()"
                @blur="$v.done_for.$touch()"
              >
                <template v-slot:label>
                  <span class="red--text"> *</span> {{ $t("workDoneFor") }}
                </template></v-text-field
              >
            </v-row>
            <v-row no-gutters>
              <v-text-field
                v-model="subdivision"
                label="Sub-Division"
                outlined
                dense
              ></v-text-field>
            </v-row>
            <v-row no-gutters class="mt-4 mb-2">
              <span
                class="font-weight-medium grey--text text--darken-4 text-subtitle-2"
                >{{ $t("lotNumber") }}</span
              ><v-tooltip
                :right="!$vuetify.breakpoint.smAndDown"
                :bottom="$vuetify.breakpoint.smAndDown"
                max-width="400"
                class="pa-0"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="ml-4"
                    color="primary"
                    dark
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-information-outline
                  </v-icon>
                </template>
                <span>{{ $t("lotNumberToolTip") }}</span>
              </v-tooltip>
            </v-row>
            <v-row no-gutters>
              <v-text-field
                v-model="lot_number"
                label="Lot Number"
                outlined
                dense
              ></v-text-field>
            </v-row>
            <v-row no-gutters class="mt-4 mb-2">
              <span
                class="font-weight-medium grey--text text--darken-4 text-subtitle-2"
                >Excavation Area</span
              ><v-tooltip
                :right="!$vuetify.breakpoint.smAndDown"
                :bottom="$vuetify.breakpoint.smAndDown"
                max-width="400"
                class="pa-0"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="ml-4"
                    color="primary"
                    dark
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-information-outline
                  </v-icon>
                </template>
                <span>{{ $t("excavationAreaOnPropertyToolTip") }}</span>
              </v-tooltip>
            </v-row>
            <div v-if="excavationAreaTextBox">
              <v-row no-gutters>
                <v-text-field
                  v-model="extent"
                  label="Enter Excavation Area on property"
                  outlined
                  dense
                >
                  <template v-slot:label>
                    Enter Excavation Area Type
                  </template></v-text-field
                >
              </v-row>
              <v-row no-gutters>
                <a
                  class="primary--text body-2"
                  @click="disableExcavationAreaTextBox()"
                  >{{ $t("searchFromList") }}</a
                >
              </v-row>
            </div>
            <div v-else>
              <v-select
                v-model="extent"
                :class="
                  extent && $vuetify.breakpoint.smAndDown
                    ? 'select-container'
                    : ''
                "
                :items="ohioSelectExcavationAreaOnProperty"
                :menu-props="{ bottom: true, offsetY: true }"
                label="Excavation Area"
                outlined
                dense
                item-value="text"
                @change="emptyExcavationArea"
              >
                <template v-slot:label> Excavation Area </template></v-select
              >
              <v-row no-gutters>
                <a
                  class="primary--text body-2"
                  @click="enableExcavationAreaTextBox()"
                  >I don’t see my type of Excavation Area</a
                >
              </v-row>
            </div>
            <div>
              <v-row no-gutters class="mt-4 mb-2">
                <span
                  class="font-weight-medium grey--text text--darken-4 text-subtitle-2"
                  >{{ $t("distanceOfRoad") }}</span
                ><v-tooltip
                  :right="!$vuetify.breakpoint.smAndDown"
                  :bottom="$vuetify.breakpoint.smAndDown"
                  max-width="400"
                  class="pa-0"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      class="ml-4"
                      color="primary"
                      dark
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-information-outline
                    </v-icon>
                  </template>
                  <span>{{ $t("distanceOfRoadTooltip") }}</span>
                </v-tooltip>
              </v-row>
              <div v-if="distanceOffRoadTextBox">
                <v-row no-gutters>
                  <v-text-field
                    v-model="distance_from_road"
                    label="Enter Distance off Road"
                    outlined
                    dense
                    suffix="Feet"
                    type="number"
                    min="0"
                    @input="validateDistance"
                  >
                    <template v-slot:label>
                      Enter Distance off Road
                    </template></v-text-field
                  >
                </v-row>
                <v-row no-gutters>
                  <a
                    class="primary--text body-2"
                    @click="disableDistanceOffRoadTextBox()"
                  >
                    Choose from options
                  </a>
                </v-row>
              </div>
              <div v-else>
                <v-radio-group v-model="distance_from_road" column>
                  <v-row no-gutters>
                    <v-col cols="12" sm="6" md="6" lg="6">
                      <v-radio value="Less than 200 ft">
                        <template v-slot:label>
                          <div
                            class="font-weight-regular grey--text text--darken-4 text-subtitle-2"
                          >
                            {{ $t("lessThan200ft") }}
                          </div>
                        </template>
                      </v-radio>
                      <v-radio value="201 ft – 500 ft">
                        <template v-slot:label>
                          <div
                            class="font-weight-regular grey--text text--darken-4 text-subtitle-2"
                          >
                            {{ $t("label201ftTo500ft") }}
                          </div>
                        </template>
                      </v-radio>
                      <v-radio value="501 ft – 1000 ft">
                        <template v-slot:label>
                          <div
                            class="font-weight-regular grey--text text--darken-4 text-subtitle-2"
                          >
                            {{ $t("label501ftTo1000ft") }}
                          </div>
                        </template>
                      </v-radio>
                      <v-radio value="1001 ft – 2000 ft">
                        <template v-slot:label>
                          <div
                            class="font-weight-regular grey--text text--darken-4 text-subtitle-2"
                          >
                            {{ $t("label1001ftTo2000ft") }}
                          </div>
                        </template>
                      </v-radio>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="6"
                      lg="6"
                      :class="$vuetify.breakpoint.smAndDown ? 'mt-2' : 'pa-0'"
                    >
                      <v-radio value="2001 ft – 3000 ft">
                        <template v-slot:label>
                          <div
                            class="font-weight-regular grey--text text--darken-4 text-subtitle-2"
                          >
                            {{ $t("label2001ftTo3000ft") }}
                          </div>
                        </template>
                      </v-radio>
                      <v-radio value="3001 ft – 4000 ft">
                        <template v-slot:label>
                          <div
                            class="font-weight-regular grey--text text--darken-4 text-subtitle-2"
                          >
                            {{ $t("label3001ftTo4000ft") }}
                          </div>
                        </template>
                      </v-radio>
                      <v-radio value="4001 Ft – 5000 ft">
                        <template v-slot:label>
                          <div
                            class="font-weight-regular grey--text text--darken-4 text-subtitle-2"
                          >
                            {{ $t("label4001ftTo5000ft") }}
                          </div>
                        </template>
                      </v-radio>
                    </v-col>
                  </v-row>
                </v-radio-group>
                <v-row no-gutters>
                  <a
                    class="primary--text body-2"
                    @click="enableDistanceOffRoadTextBox()"
                    >I don’t see my type of Distance off Road</a
                  >
                </v-row>
              </div>
            </div>

            <v-row no-gutters class="mt-4 mb-2">
              <span class="red--text mr-1"> *</span>
              <span
                class="font-weight-medium grey--text text--darken-4 text-subtitle-2"
                >Work Area Description</span
              ><v-tooltip
                :right="!$vuetify.breakpoint.smAndDown"
                :bottom="$vuetify.breakpoint.smAndDown"
                max-width="400"
                class="pa-0"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="ml-4"
                    color="primary"
                    dark
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-information-outline
                  </v-icon>
                </template>
                <span>Provide a detailed description of your work area</span>
              </v-tooltip>
            </v-row>
            <v-row no-gutters>
              <v-textarea
                v-model="direction_of_road"
                label="Work Area Description"
                :error-messages="workAreaDescriptionErrors"
                rows="3"
                counter="1000"
                outlined
                dense
                required
                @input="$v.direction_of_road.$touch()"
                @blur="$v.direction_of_road.$touch()"
              ></v-textarea>
            </v-row>
            <v-row no-gutters class="mt-4 mb-2">
              <span
                class="font-weight-medium grey--text text--darken-4 text-subtitle-2"
                >{{ $t("typeOfWork") }}</span
              ><v-tooltip
                :right="!$vuetify.breakpoint.smAndDown"
                :bottom="$vuetify.breakpoint.smAndDown"
                max-width="400"
                class="pa-0"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="ml-4"
                    color="primary"
                    dark
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-information-outline
                  </v-icon>
                </template>
                <span>{{ $t("typeOfWorkToolTip") }}</span>
              </v-tooltip>
            </v-row>
            <div v-if="typeOfWorkTextBox">
              <v-row no-gutters>
                <v-text-field
                  v-model="work_type"
                  label="Enter Type of Work"
                  outlined
                  dense
                  required
                  :hide-details="typeOFWorkErrors.length == 0"
                  :error-messages="typeOFWorkErrors"
                  @input="$v.work_type.$touch()"
                  @blur="$v.work_type.$touch()"
                >
                  <template v-slot:label>
                    <span class="red--text"> *</span> Enter
                    {{ $t("typeOfWork") }}
                  </template></v-text-field
                >
              </v-row>
              <v-row no-gutters>
                <a
                  class="primary--text my-2 body-2"
                  @click="disableTypeOfWorkTextBox()"
                  >{{ $t("searchFromList") }}</a
                >
              </v-row>
            </div>
            <div v-else>
              <v-row no-gutters>
                <v-select
                  v-model="work_type"
                  :class="
                    work_type && $vuetify.breakpoint.smAndDown
                      ? 'select-container'
                      : ''
                  "
                  :items="workTypeOptions"
                  :menu-props="{ bottom: true, offsetY: true }"
                  label="Type of Work"
                  outlined
                  dense
                  required
                  item-value="text"
                  :error-messages="typeOFWorkErrors"
                  :hide-details="typeOFWorkErrors.length == 0"
                  @change="emptyWorkType"
                  @input="$v.work_type.$touch()"
                  @blur="$v.work_type.$touch()"
                  ><template v-slot:label>
                    <span class="red--text"> *</span> {{ $t("typeOfWork") }}
                  </template></v-select
                >
              </v-row>
              <v-row no-gutters>
                <a
                  class="primary--text my-2 body-2"
                  @click="enableTypeOfWorkTextBox()"
                  >{{ $t("dontSeeTypeOfWork") }}</a
                >
              </v-row>
            </div>
            <div class="mb-5">
              <v-row no-gutters class="mt-4 mb-2">
                <span
                  class="font-weight-medium grey--text text--darken-4 text-subtitle-2"
                  >{{ $t("meansOfExcavation") }}</span
                ><v-tooltip
                  :right="!$vuetify.breakpoint.smAndDown"
                  :bottom="$vuetify.breakpoint.smAndDown"
                  max-width="400"
                  class="pa-0"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      class="ml-4"
                      color="primary"
                      dark
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-information-outline
                    </v-icon>
                  </template>
                  <span>{{ $t("meansOfExcavationToolTip") }}</span>
                </v-tooltip>
              </v-row>
              <div v-if="meansOfExcavationTextBox">
                <v-row no-gutters>
                  <v-text-field
                    v-model="means_of_excavation"
                    label="Enter Means of Excavation"
                    outlined
                    dense
                    required
                    @change="emptyMeansOfExcavation"
                    :error-messages="meansOfExcavationErrors"
                    :hide-details="meansOfExcavationErrors.length == 0"
                    @input="$v.means_of_excavation.$touch()"
                    @blur="$v.means_of_excavation.$touch()"
                  >
                    <template v-slot:label>
                      Enter Means of Excavation
                    </template></v-text-field
                  >
                </v-row>
                <v-row no-gutters>
                  <a
                    class="primary--text body-2 pt-2"
                    @click="disableMeansOfExcavationTextBox()"
                    >{{ $t("searchFromList") }}</a
                  >
                </v-row>
              </div>
              <div v-else>
                <v-row no-gutters>
                  <v-select
                    v-model="means_of_excavation"
                    :items="meansOfExcavationOptions"
                    :menu-props="{ bottom: true, offsetY: true }"
                    label="Means of Excavation"
                    outlined
                    dense
                    required
                    item-value="text"
                    @change="emptyMeansOfExcavation"
                    :error-messages="meansOfExcavationErrors"
                    :hide-details="meansOfExcavationErrors.length == 0"
                    @input="$v.means_of_excavation.$touch()"
                    @blur="$v.means_of_excavation.$touch()"
                    ><template v-slot:label>
                      <span class="red--text"> *</span>
                      {{ $t("meansOfExcavation") }}
                    </template></v-select
                  >
                </v-row>
                <v-row no-gutters>
                  <a
                    class="primary--text body-2"
                    @click="enableMeansOfExcavationTextBox()"
                    >I don’t see my type of Means of Excavation</a
                  >
                </v-row>
              </div>
            </div>
            <v-row no-gutters class="mt-4 mb-2">
              <span
                class="font-weight-medium grey--text text--darken-4 text-subtitle-2"
                >Directions to Work Area</span
              ><v-tooltip
                :right="!$vuetify.breakpoint.smAndDown"
                :bottom="$vuetify.breakpoint.smAndDown"
                max-width="400"
                class="pa-0"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="ml-4"
                    color="primary"
                    dark
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-information-outline
                  </v-icon>
                </template>
                <span>{{ $t("directionsToAddressToolTip") }}</span>
              </v-tooltip>
            </v-row>
            <v-row no-gutters>
              <v-textarea
                v-model="direction_to_work_area"
                label="Directions to Work Area"
                :error-messages="locationErrors"
                rows="2"
                counter="120"
                outlined
                dense
                required
                @input="$v.direction_to_work_area.$touch()"
                @blur="$v.direction_to_work_area.$touch()"
              ></v-textarea>
            </v-row>
            <v-row no-gutters class="mt-4 mb-2">
              <span
                class="font-weight-medium grey--text text--darken-4 text-subtitle-2"
                >{{ $t("additionalComments") }}</span
              ><v-tooltip
                :right="!$vuetify.breakpoint.smAndDown"
                :bottom="$vuetify.breakpoint.smAndDown"
                max-width="400"
                class="pa-0"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="ml-4"
                    color="primary"
                    dark
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-information-outline
                  </v-icon>
                </template>
                <span>{{ $t("ohioAdditionalCommentsToolTip") }}</span>
              </v-tooltip>
            </v-row>
            <v-row no-gutters>
              <v-textarea
                v-model="remarks"
                label="Comments"
                :error-messages="commentsErrors"
                rows="2"
                counter="120"
                outlined
                dense
                required
                @input="$v.remarks.$touch()"
                @blur="$v.remarks.$touch()"
              ></v-textarea>
            </v-row>
            <v-row no-gutters class="mt-4 mb-2">
              <span
                class="font-weight-medium grey--text text--darken-4 text-subtitle-2"
                >{{ $t("additionalInformation") }}
                <span
                  class="font-weight-normal grey--text text--darken-1 text-caption"
                >
                  {{ $t("checkAllApply") }}</span
                ></span
              >
            </v-row>
            <div>
              <v-row no-gutters>
                <v-checkbox v-model="white_paint" hide-details>
                  <template v-slot:label>
                    <div
                      class="font-weight-regular grey--text text--darken-4 text-subtitle-2"
                    >
                      {{ $t("whiteLineLabel") }}
                      <span
                        class="font-weight-regular grey--text text--darken-1 text-subtitle-2"
                      >
                        {{ $t("whiteLineSubLabel") }}</span
                      >
                    </div>
                  </template>
                </v-checkbox>
              </v-row>
              <v-row no-gutters>
                <v-checkbox v-model="blasting" hide-details>
                  <template v-slot:label>
                    <div
                      class="font-weight-regular grey--text text--darken-4 text-subtitle-2"
                    >
                      {{ $t("blastingLabel") }}
                      <span
                        class="font-weight-regular grey--text text--darken-1 text-subtitle-2"
                      >
                        {{ $t("blastingSubLabel") }}</span
                      >
                    </div>
                  </template></v-checkbox
                >
              </v-row>
              <v-row no-gutters>
                <v-checkbox
                  v-model="isRailOrHighway"
                  hide-details
                  @change="checkRR_or_highway"
                >
                  <template v-slot:label>
                    <div
                      class="font-weight-regular grey--text text--darken-4 text-subtitle-2"
                    >
                      {{ $t("railRoadHighwayLabel") }}
                      <span
                        class="font-weight-regular grey--text text--darken-1 text-subtitle-2"
                      >
                        {{ $t("railRoadHighwaySubLabel") }}</span
                      >
                    </div>
                  </template>
                </v-checkbox>
              </v-row>
              <v-row
                no-gutters
                :class="
                  $store.state.Ticket.isEdit &&
                  ($store.state.Ticket.isEdit == 'UpdateTicket' ||
                    $store.state.Ticket.isEdit == 'Digin')
                    ? 'mb-6'
                    : 'mb-16'
                "
              >
                <v-radio-group
                  v-if="isRailOrHighway"
                  v-model="rr_or_highway"
                  class="ml-6"
                  row
                >
                  <v-radio value="RR">
                    <template v-slot:label>
                      <div
                        class="font-weight-regular grey--text text--darken-4 text-subtitle-2"
                      >
                        Railway
                      </div>
                    </template>
                  </v-radio>
                  <v-radio value="HWY">
                    <template v-slot:label>
                      <div
                        class="font-weight-regular grey--text text--darken-4 text-subtitle-2"
                      >
                        Highway
                      </div>
                    </template>
                  </v-radio>
                </v-radio-group>
              </v-row>
            </div>
            <div v-if="$store.state.Ticket.isEdit == 'UpdateTicket'">
              <v-row no-gutters class="mt-4 mb-2">
                <span
                  class="font-weight-medium grey--text text--darken-4 text-subtitle-2"
                  >{{ $t("reasonForUpdate") }}
                </span>
              </v-row>
              <v-row no-gutters>
                <v-select
                  v-model="reason"
                  :items="reasonForUpdateArray"
                  label="Reason for the update"
                  outlined
                  dense
                  item-value="text"
                  required
                  :menu-props="{ bottom: true, offsetY: true }"
                  :error-messages="reasonErrors"
                  :hide-details="reasonErrors.length == 0"
                  @input="$v.reason.$touch()"
                  @blur="$v.reason.$touch()"
                >
                  <template v-slot:label>
                    <span class="red--text"> *</span>
                    {{ $t("reasonForUpdate") }}
                  </template>
                </v-select>
              </v-row>

              <v-row no-gutters class="mt-4 mb-2">
                <span
                  class="font-weight-medium grey--text text--darken-4 text-subtitle-2"
                  >{{ $t("remarksOrSpecificInfo") }}</span
                >
              </v-row>
              <v-row no-gutters>
                <v-textarea
                  v-model="remarks"
                  label="Remarks"
                  rows="2"
                  counter="120"
                  outlined
                  dense
                  hide-details
                ></v-textarea>
              </v-row>
              <v-row no-gutters class="mt-4 mb-2">
                <span
                  class="font-weight-medium grey--text text--darken-4 text-subtitle-2"
                  >{{ $t("membersToAdd") }}
                </span>
              </v-row>
              <v-row no-gutters class="mb-16">
                <v-autocomplete
                  v-model="selectedMembersToAdd"
                  :items="membersToAddList"
                  :menu-props="{ bottom: true, offsetY: true }"
                  :label="$t('membersToAdd')"
                  outlined
                  dense
                  required
                  multiple
                  item-text="mbcode_name"
                  item-value="mbcode"
                  @input="searchCountyMembers = null"
                  :search-input.sync="searchCountyMembers"
                >
                  <template v-slot:prepend-item>
                    <v-list-item>
                      <v-text-field
                        v-model="searchCountyMembers"
                        prepend-inner-icon="mdi-magnify"
                        :label="$t('searchMembersToAdd')"
                        single-line
                        hide-details
                        flat
                        dense
                        variant="underlined"
                        clear-icon="mdi-close-circle"
                        clearable
                      >
                      </v-text-field>
                    </v-list-item>
                  </template>
                  <template
                    v-slot:selection="{ attrs, item, parent, selected }"
                  >
                    <v-chip
                      size="small"
                      density="compact"
                      v-bind="attrs"
                      :input-value="selected"
                    >
                      <span class="pr-2">
                        {{ item.mbcode_name }}
                      </span>
                      <v-icon small @click="parent.selectItem(item)">
                        mdi-close-circle
                      </v-icon>
                    </v-chip>
                  </template>
                </v-autocomplete>
              </v-row>
            </div>

            <v-row
              no-gutters
              class="mt-4 mb-2"
              v-if="$store.state.Ticket.isEdit == 'Digin'"
            >
              <span class="red--text mr-1"> *</span>
              <span
                class="font-weight-medium grey--text text--darken-4 text-subtitle-2"
                >{{ $t("DigInTypeOfLine") }}
              </span>
            </v-row>
            <div v-if="$store.state.Ticket.isEdit == 'Digin'">
              <v-row no-gutters>
                <v-checkbox v-model="digin_catv" hide-details>
                  <template v-slot:label>
                    <div
                      class="font-weight-regular grey--text text--darken-4 text-subtitle-2"
                    >
                      {{ $t("CATV") }}
                    </div>
                  </template>
                </v-checkbox>
              </v-row>
              <v-row no-gutters>
                <v-checkbox v-model="digin_elec" hide-details>
                  <template v-slot:label>
                    <div
                      class="font-weight-regular grey--text text--darken-4 text-subtitle-2"
                    >
                      {{ $t("Electric") }}
                    </div>
                  </template></v-checkbox
                >
              </v-row>
              <v-row no-gutters>
                <v-checkbox v-model="digin_fiber" hide-details>
                  <template v-slot:label>
                    <div
                      class="font-weight-regular grey--text text--darken-4 text-subtitle-2"
                    >
                      {{ $t("Fiber") }}
                    </div>
                  </template>
                </v-checkbox>
              </v-row>
              <v-row no-gutters>
                <v-checkbox v-model="digin_gas" hide-details>
                  <template v-slot:label>
                    <div
                      class="font-weight-regular grey--text text--darken-4 text-subtitle-2"
                    >
                      {{ $t("Gas") }}
                    </div>
                  </template>
                </v-checkbox>
              </v-row>
              <v-row no-gutters>
                <v-checkbox v-model="digin_blowing" hide-details>
                  <template v-slot:label>
                    <div
                      class="font-weight-regular grey--text text--darken-4 text-subtitle-2"
                    >
                      Gas Glowing
                    </div>
                  </template>
                </v-checkbox>
              </v-row>
              <v-row no-gutters>
                <v-checkbox v-model="digin_phone" hide-details>
                  <template v-slot:label>
                    <div
                      class="font-weight-regular grey--text text--darken-4 text-subtitle-2"
                    >
                      {{ $t("Phone") }}
                    </div>
                  </template>
                </v-checkbox>
              </v-row>
              <v-row no-gutters>
                <v-checkbox v-model="digin_sewer" hide-details>
                  <template v-slot:label>
                    <div
                      class="font-weight-regular grey--text text--darken-4 text-subtitle-2"
                    >
                      {{ $t("Sewer") }}
                    </div>
                  </template>
                </v-checkbox>
              </v-row>
              <v-row no-gutters>
                <v-checkbox v-model="digin_water" hide-details>
                  <template v-slot:label>
                    <div
                      class="font-weight-regular grey--text text--darken-4 text-subtitle-2"
                    >
                      {{ $t("Water") }}
                    </div>
                  </template>
                </v-checkbox>
              </v-row>
              <v-row no-gutters>
                <v-checkbox v-model="digin_spill">
                  <template v-slot:label>
                    <div
                      class="font-weight-regular grey--text text--darken-4 text-subtitle-2"
                    >
                      {{ $t("Chemical Spill") }}
                    </div>
                  </template>
                </v-checkbox>
              </v-row>
              <v-row no-gutters>
                <v-select
                  v-model="digin_other"
                  :items="digOtherArray"
                  :menu-props="{ bottom: true, offsetY: true }"
                  outlined
                  dense
                  required
                  ><template v-slot:label>
                    {{ $t("Unknown") }}
                  </template>
                </v-select>
              </v-row>
              <v-row no-gutters class="mt-4 mb-2">
                <span
                  class="font-weight-medium grey--text text--darken-4 text-subtitle-2"
                  >{{ $t("membersToAdd") }}
                </span>
              </v-row>
              <v-row no-gutters class="mb-16">
                <v-autocomplete
                  v-model="selectedMembersToAdd"
                  :items="membersToAddList"
                  :menu-props="{ bottom: true, offsetY: true }"
                  :label="$t('membersToAdd')"
                  outlined
                  dense
                  required
                  multiple
                  item-text="mbcode_name"
                  item-value="mbcode"
                  @input="searchCountyMembers = null"
                  :search-input.sync="searchCountyMembers"
                >
                  <template v-slot:prepend-item>
                    <v-list-item>
                      <v-text-field
                        v-model="searchCountyMembers"
                        prepend-inner-icon="mdi-magnify"
                        :label="$t('searchMembersToAdd')"
                        single-line
                        hide-details
                        flat
                        dense
                        variant="underlined"
                        clear-icon="mdi-close-circle"
                        clearable
                      >
                      </v-text-field>
                    </v-list-item>
                  </template>
                  <template
                    v-slot:selection="{ attrs, item, parent, selected }"
                  >
                    <v-chip
                      size="small"
                      density="compact"
                      v-bind="attrs"
                      :input-value="selected"
                    >
                      <span class="pr-2">
                        {{ item.mbcode_name }}
                      </span>
                      <v-icon small @click="parent.selectItem(item)">
                        mdi-close-circle
                      </v-icon>
                    </v-chip>
                  </template>
                </v-autocomplete>
              </v-row>
            </div>
            <v-row v-if="$vuetify.breakpoint.mdAndUp" no-gutters class="mb-16">
              <v-btn
                color="primary"
                class="text-none rounded-lg elevation-0"
                outlined
                width="90"
                @click="backButton()"
              >
                {{ $t("back") }}
              </v-btn>

              <v-btn
                class="ml-6 primary text-none rounded-lg elevation-0"
                width="90"
                depressed
                :disabled="$v.$invalid || diginType"
                @click="continueButton"
              >
                {{ $t("continue") }}
              </v-btn>
            </v-row>
          </v-col>
        </v-row>
      </v-col>

      <v-col v-if="$vuetify.breakpoint.mdAndUp">
        <v-card
          width="90%"
          class="rounded-lg mx-4"
          elevation="0"
          style="border: 1px solid #e0e0e0; border-radius: 8%"
        >
          <v-card-title class="pt-2">
            <v-row>
              <v-col class="pt-2">
                <span
                  class="font-weight-medium grey--text text--darken-4 text-subtitle-1 mr-2 my-2"
                  >{{
                    $store.state.Ticket.ticketData ? streetAddress : ""
                  }}</span
                >
              </v-col>
              <v-col class="d-flex justify-end pt-2">
                <a
                  class="primary--text my-2 body-2"
                  @click="$emit('editLocation')"
                >
                  <img
                    src="@/assets/images/edit_location.svg"
                    role="img"
                    alt="edit location Logo"
                  />
                  <span class="mb-auto">{{ $t("editLocation") }}</span></a
                ></v-col
              >
            </v-row>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col
                cols="2"
                class="py-1"
                :class="
                  $store.state.Ticket.ticketData.county &&
                  $store.state.Ticket.ticketData.county != ''
                    ? ''
                    : 'my-auto'
                "
              >
                {{ $t("county") }} :
              </v-col>
              <v-col
                v-if="
                  $store.state.Ticket.ticketData.county &&
                  $store.state.Ticket.ticketData.county != ''
                "
                class="font-weight-medium grey--text text--darken-4 text-subtitle-2 py-1"
              >
                {{
                  $store.state.Ticket.ticketData
                    ? $store.state.Ticket.ticketData.county
                    : "—"
                }}
              </v-col>
              <v-col
                v-else
                class="font-weight-medium grey--text text--darken-4 text-subtitle-2 py-1"
              >
                <v-text-field
                  v-model="county"
                  outlined
                  dense
                  required
                  hide-details
                  placeholder="Enter County"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="2"
                class="py-1"
                :class="
                  $store.state.Ticket.ticketData.place &&
                  $store.state.Ticket.ticketData.place != ''
                    ? ''
                    : 'my-auto'
                "
              >
                {{ $t("place") }} :
              </v-col>
              <v-col
                v-if="
                  $store.state.Ticket.ticketData.place &&
                  $store.state.Ticket.ticketData.place != ''
                "
                class="font-weight-medium grey--text text--darken-4 text-subtitle-2 py-1"
              >
                {{
                  $store.state.Ticket.ticketData
                    ? $store.state.Ticket.ticketData.place
                    : "—"
                }}
              </v-col>
              <v-col
                v-else
                class="font-weight-medium grey--text text--darken-4 text-subtitle-2 py-1"
              >
                <v-text-field
                  v-model="place"
                  outlined
                  dense
                  required
                  hide-details
                  placeholder="Enter Place"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="2"
                class="py-1"
                :class="
                  $store.state.Ticket.ticketData.street &&
                  $store.state.Ticket.ticketData.street != ''
                    ? ''
                    : 'my-auto'
                "
              >
                {{ $t("street") }} :
              </v-col>
              <v-col
                v-if="
                  $store.state.Ticket.ticketData.street &&
                  $store.state.Ticket.ticketData.street != ''
                "
                class="font-weight-medium grey--text text--darken-4 text-subtitle-2 py-1"
              >
                {{ $store.state.Ticket.ticketData ? streetAddress : "—" }}
              </v-col>
              <v-col
                v-else
                class="font-weight-medium grey--text text--darken-4 text-subtitle-2 py-1"
              >
                <v-text-field
                  v-model="street"
                  outlined
                  dense
                  required
                  hide-details
                  placeholder="Enter Street"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="2"
                class="py-1"
                :class="streetLocation.cross1 ? '' : 'my-auto'"
              >
                <span class="red--text"> *</span> {{ $t("cross1") }} :
              </v-col>
              <v-col
                class="font-weight-medium grey--text text--darken-4 text-subtitle-2 py-1"
              >
                <v-text-field
                  v-model="cross1"
                  outlined
                  dense
                  required
                  :error-messages="cross1Error"
                  @input="$v.cross1.$touch()"
                  @blur="$v.cross1.$touch()"
                  placeholder="Enter Cross 1"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="2"
                class="py-1"
                :class="streetLocation.cross2 ? '' : 'my-auto'"
              >
                {{ $t("cross2") }} :
              </v-col>
              <v-col
                class="font-weight-medium grey--text text--darken-4 text-subtitle-2 py-1"
              >
                <v-text-field
                  v-model="cross2"
                  outlined
                  dense
                  required
                  hide-details
                  placeholder="Enter Cross 2"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <MapView :is-edit-view="isEditView" /> </v-card
      ></v-col>

      <v-bottom-navigation
        v-if="$vuetify.breakpoint.smAndDown"
        fixed
        horizontal
        height="70"
        class="px-2 py-4"
        ><v-row no-gutters justify="space-between">
          <v-col cols="1" class="pa-0">
            <v-btn
              outlined
              color="primary"
              class="rounded-lg"
              height="50"
              @click="backButton"
            >
              <v-icon color="primary">mdi-arrow-left</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="9" class="pa-0">
            <v-btn
              color="primary"
              class="text-none rounded-lg font-weight-regular text-h6 white--text"
              height="50"
              block
              :disabled="$v.$invalid || diginType"
              @click="continueButton"
              ><span class="white--text">{{ $t("continue") }}</span>
            </v-btn>
          </v-col>
        </v-row></v-bottom-navigation
      >
    </v-row>
  </v-container>
</template>
<script>
import excavationAreaJSON from "@/static/excavationAreaOnProperty.json";
import { validationMixin } from "vuelidate";
import { helpers } from "vuelidate/lib/validators";
import { required, minLength, maxLength } from "vuelidate/lib/validators";
import MapView from "../../MapView.vue";
import moment from "moment";
import UserDataMixin from "@/mixins/UserData";
import AssociateTicketExcvDetails from "../../AssociateTicketExcvDetails.vue";
const phone = helpers.regex(
  "serial",
  /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/
);
const dateText = helpers.regex(
  "date",
  /(0\d{1}|1[0-2])\/([0-2]\d{1}|3[0-1])\/(19|20)(\d{2})/
);

export default {
  components: { MapView, AssociateTicketExcvDetails },
  mixins: [validationMixin, UserDataMixin],
  validations() {
    const isFutureDate = (value) => {
      if (!value) return true;
      const today = new Date(this.legalDates.workDate);
      today.setHours(0, 0, 0, 0);
      return new Date(value) >= today;
    };

    const isFutureTime = (value) => {
      if (!value || !this.work_date) return true;
      // Convert work_date from MM/DD/YYYY to YYYY-MM-DD
      const [month, day, year] = this.work_date.split("/");
      const formattedDate = `${year}-${month}-${day}`;
      const selectedDateTime = new Date(
        `${formattedDate}T${this.convertTo24Hour(value)}`
      );
      return selectedDateTime >= new Date(this.legalDates.workDate);
    };
    let validationFieldsObject = {
      work_date: {
        required,
        dateText,
        isFutureDate,
      },
      cross1: { required },
      contact: { required },
      contact_phone: { required, phone },
      work_type: { required, maxLength: maxLength(80) },
      means_of_excavation: { required, maxLength: maxLength(80) },
      scheduleTime: { required, isFutureTime },
      direction_of_road: { required },
      done_for: { required, maxLength: maxLength(80) },
      direction_to_work_area: { maxLength: maxLength(120) },
      remarks: { maxLength: maxLength(120) },
    };
    if (this.$store.state.Ticket.isEdit == "UpdateTicket")
      validationFieldsObject.reason = { required };
    if (
      this.$store.state.Ticket.isEdit &&
      this.$store.state.Ticket.isEdit === "Digin"
    ) {
      validationFieldsObject.scheduleTime = { required };
      validationFieldsObject.work_date = {
        required,
        dateText,
      };
    }
    if (!this.$store.state.Ticket.isEdit)
      validationFieldsObject.work_date.past_date = (value) => {
        const [month, day, year] = value.split("/");
        const selectedDate = new Date(`${year}-${month}-${day}`);
        const currentDate = new Date();
        return selectedDate > currentDate;
      };
    return validationFieldsObject;
  },
  props: {
    mapView: {
      type: String,
      default: () => "",
    },
    isEditView: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      digin_catv: false,
      digin_elec: false,
      digin_fiber: false,
      digin_gas: false,
      digin_blowing: false,
      digin_phone: false,
      digin_sewer: false,
      digin_water: false,
      digin_spill: false,
      digin_other: "",
      digOtherArray: ["PIPE", "WIRE", "UNSURE"],
      center: this.$store.state.Ticket.ticketData.center,
      picker: new Date(),
      menu1: false,
      menu2: false,
      typeOfWorkTextBox: false,
      excavationAreaTextBox: false,
      meansOfExcavationTextBox: false,
      distanceOffRoadTextBox: false,
      mapViewHtml: "",
      startDate: "",
      ohioSelectExcavationAreaOnProperty:
        excavationAreaJSON.excavationAreaOnProperty,
      workTypeOptions: [],
      meansOfExcavationOptions: [],
      reasonForUpdateArray: [
        "REQUESTING REMARKINGS",
        "REQUESTING REMARKINGS, ONGOING PROJECT",
        "MARKINGS DESTROYED, REQUESTING REMARKINGS",
        "OTHER",
      ],
      date: "",
      work_date: "",
      contact: "",
      contact_phone: "",
      work_type: "",
      means_of_excavation: "",
      reason: "",
      remarks: "",
      subdivision: "",
      lot_number: "",
      done_for: "",
      extent: "",
      white_paint: false,
      blasting: false,
      isRailOrHighway: false,
      rr_or_highway: "",
      direction_to_work_area: "",
      distance_from_road: "",
      direction_of_road: "",
      selectedMembersToAdd: [],
      membersToAddList: [],
      searchCountyMembers: null,
      cross1: "",
      cross2: "",
      place: "",
      street: "",
      county: "",
      time: null,
      scheduleTime: null,
      legalDates: [],
    };
  },
  computed: {
    diginType() {
      if (this.$store.state.Ticket.isEdit !== "Digin") return false;
      else
        return !(
          this.digin_blowing ||
          this.digin_other ||
          this.digin_spill ||
          this.digin_water ||
          this.digin_sewer ||
          this.digin_phone ||
          this.digin_gas ||
          this.digin_elec ||
          this.digin_catv ||
          this.digin_fiber
        );
    },
    streetLocation() {
      return this.$store.state.Ticket.ticketData.streetLocation;
    },
    getNowDate() {
      var beginDate = new Date(this.picker.setDate(this.picker.getDate()));
      while (!this.disableWeekends(beginDate)) {
        beginDate.setDate(beginDate.getDate() + 1);
      }
      this.setStartDate(beginDate);
      return beginDate.toISOString().slice(0, 10);
    },
    getEndDate() {
      var endDate = "";
      let count = 1,
        noOfDaysToAdd = 10;
      if (this.startDate) {
        let beginDate = this.startDate;
        let tempDate = "";
        while (count < noOfDaysToAdd) {
          tempDate = new Date(beginDate.setDate(beginDate.getDate() + 1));
          if (tempDate.getDay() != 0 && tempDate.getDay() != 6) {
            count++;
          }
        }
        endDate = new Date(tempDate.setDate(tempDate.getDate()));
      }
      return endDate.toISOString().slice(0, 10);
    },
    companyExcavatorType() {
      let excavatorType = "";
      if (
        this.$store.state.Company.company.centers &&
        this.$store.state.Company.company.centers.length > 0
      ) {
        excavatorType = this.$store.state.Company.company.centers.filter(
          (item) => {
            return item.abbrev === this.center;
          }
        )[0].excavator_type;
      }
      return excavatorType;
    },
    scheduleTimeError() {
      const errors = [];
      if (!this.$v.scheduleTime.$dirty) return errors;
      !this.$v.scheduleTime.required &&
        errors.push(this.$t("scheduledTimeError"));
      if (!this.$v.scheduleTime.isFutureTime) {
        errors.push(
          "Time cannot be before " +
            new Date(this.legalDates.workDate).toLocaleString()
        );
      }
      return errors;
    },
    contactErrors() {
      const errors = [];
      if (!this.$v.contact.$dirty) return errors;
      !this.$v.contact.required &&
        errors.push(this.$t("contactNameRequiredError"));
      return errors;
    },
    dateFormattedErrors() {
      const errors = [];
      if (!this.$v.work_date.$dirty) return errors;
      !this.$v.work_date.required &&
        errors.push(this.$t("dateFormattedRequiredError"));
      !this.$v.work_date.dateText &&
        errors.push(this.$t("dateFormattedInvalidError"));
      if (!this.$store.state.Ticket.isEdit)
        !this.$v.work_date.past_date && errors.push(this.$t("pastDateError"));
      if (!this.$v.work_date.isFutureDate) {
        errors.push(
          "Date cannot be before " +
            new Date(this.legalDates.workDate).toLocaleString()
        );
      }
      return errors;
    },
    contactPhoneErrors() {
      const errors = [];
      if (!this.$v.contact_phone.$dirty) return errors;
      !this.$v.contact_phone.phone &&
        errors.push(this.$t("contactPhoneValidError"));
      !this.$v.contact_phone.required &&
        errors.push(this.$t("contactPhoneRequiredError"));
      return errors;
    },
    workDoneForErrors() {
      const errors = [];
      if (!this.$v.done_for.$dirty) return errors;
      !this.$v.done_for.required &&
        errors.push(this.$t("workDoneForRequiredError"));
      !this.$v.done_for.maxLength &&
        errors.push("Work Done For length must be at most 80 characters long");
      return errors;
    },
    directionoFRoadErrors() {
      const errors = [];
      if (!this.$v.directionoFRoad.$dirty) return errors;
      !this.$v.directionoFRoad.required &&
        errors.push(this.$t("directionoFRoadRequiredError"));
      return errors;
    },
    typeOFWorkErrors() {
      const errors = [];
      if (!this.$v.work_type.$dirty) return errors;
      !this.$v.work_type.required &&
        errors.push(this.$t("typeOFWorkRequiredError"));
      !this.$v.work_type.maxLength &&
        errors.push(this.$t("workTypeLengthError"));
      return errors;
    },
    meansOfExcavationErrors() {
      const errors = [];
      if (!this.$v.means_of_excavation.$dirty) return errors;
      !this.$v.means_of_excavation.required &&
        errors.push(this.$t("meansoFExcavationRequiredError"));
      !this.$v.means_of_excavation.maxLength &&
        errors.push(
          "Means of Excavation length must be at most 80 characters long"
        );
      return errors;
    },
    workAreaDescriptionErrors() {
      const errors = [];
      if (!this.$v.direction_of_road.$dirty) return errors;
      !this.$v.direction_of_road.required &&
        errors.push("Work Area Description is required");
      return errors;
    },
    reasonErrors() {
      const errors = [];
      if (!this.$v.reason.$dirty) return errors;
      !this.$v.reason.required && errors.push(this.$t("reasonRequiredError"));
      return errors;
    },
    locationErrors() {
      const errors = [];
      if (!this.$v.direction_to_work_area.$dirty) return errors;
      !this.$v.direction_to_work_area.maxLength &&
        errors.push(this.$t("locationLengthError"));
      return errors;
    },
    commentsErrors() {
      const errors = [];
      if (!this.$v.remarks.$dirty) return errors;
      !this.$v.remarks.maxLength && errors.push(this.$t("commentsLengthError"));
      return errors;
    },
    cross1Error() {
      const errors = [];
      if (!this.$v.cross1.$dirty) return errors;
      !this.$v.cross1.required && errors.push("Please enter cross1 address");
      return errors;
    },
    ticketData() {
      return this.$store.state.Ticket.ticketData;
    },
    streetAddress() {
      const streetFromAddress =
        this.$store.state.Ticket.ticketData?.st_from_address;
      const street = this.$store.state.Ticket.ticketData?.street;
      return (streetFromAddress ? streetFromAddress + " " : "") + street;
    },
  },
  watch: {
    date(val) {
      this.work_date = this.formatDate(this.date);
    },
    time(val) {
      this.scheduleTime = this.time;
      this.updateTimeFormat();
    },
  },
  async created() {
    if (
      !this.$store.state.Ticket.excavationDetails.legalDates ||
      this.$store.state.Ticket.isEdit === "UpdateTicket"
    )
      this.getLegalDate();
    else {
      this.legalDates = this.$store.state.Ticket.excavationDetails.legalDates;
      this.picker = new Date(
        this.$store.state.Ticket.excavationDetails.legalDates.workDate
      );
    }
    if (Object.keys(this.$store.state.Ticket.excavationDetails).length === 0)
      this.cross1 =
        this.$store.state.Ticket.ticketData.addressType === "intersection"
          ? this.$store.state.Ticket.ticketData.cross1
          : "";
    if (this.$store.state.Ticket.excavationDetails.workTypeOptions)
      this.workTypeOptions =
        this.$store.state.Ticket.excavationDetails.workTypeOptions;
    if (
      this.ticketData &&
      this.ticketData.center &&
      !this.$store.state.Ticket.excavationDetails.workTypeOptions
    ) {
      await this.getTypeOfWork();
    }
    if (this.$store.state.Ticket.excavationDetails.meansOfExcavationOptions)
      this.meansOfExcavationOptions =
        this.$store.state.Ticket.excavationDetails.meansOfExcavationOptions;
    if (
      this.ticketData &&
      this.ticketData.center &&
      !this.$store.state.Ticket.excavationDetails.meansOfExcavationOptions
    ) {
      await this.getMeansOfExcavation();
    }
    if (Object.keys(this.$store.state.Ticket.excavationDetails).length != 0) {
      if (this.$store.state.Ticket.isEdit === "UpdateTicket") {
        this.$v.work_date.$touch();
      } else if(this.$store.state.Ticket.isEdit === "Digin"){
        this.date = moment(new Date()).format("YYYY-MM-DD");
        this.work_date = moment(new Date()).format("DD/MMM/YYYY");
        this.time = await this.getTimeFromWorkDate(new Date());     
      } else {
        this.date = moment(
          this.$store.state.Ticket.excavationDetails.work_date
        ).format("YYYY-MM-DD");
        this.work_date = moment(
          this.$store.state.Ticket.excavationDetails.work_date
        ).format("DD/MMM/YYYY");
        this.time = await this.getTimeFromWorkDate(this.$store.state.Ticket.excavationDetails.work_date);
      }
      this.contact = this.$store.state.Ticket.excavationDetails.contact;
      this.contact_phone =
        this.$store.state.Ticket.excavationDetails.contact_phone;
      this.work_type = this.$store.state.Ticket.excavationDetails.work_type;
      this.means_of_excavation =
        this.$store.state.Ticket.excavationDetails.means_of_excavation;
      this.subdivision = this.$store.state.Ticket.excavationDetails.subdivision;
      this.lot_number = this.$store.state.Ticket.excavationDetails.lot_number;
      this.done_for = this.$store.state.Ticket.excavationDetails.done_for;
      this.extent = this.$store.state.Ticket.excavationDetails.extent;
      this.direction_to_work_area = this.$store.state.Ticket.excavationDetails.direction_to_work_area;
      this.remarks = this.$store.state.Ticket.excavationDetails.remarks;
      this.white_paint = this.$store.state.Ticket.excavationDetails.white_paint;
      this.blasting = this.$store.state.Ticket.excavationDetails.blasting;
      this.cross1 = this.$store.state.Ticket.ticketData.streetLocation.cross1;
      this.cross2 = this.$store.state.Ticket.ticketData.streetLocation.cross2;
      this.rr_or_highway =
        this.$store.state.Ticket.excavationDetails.rr_or_highway;
      this.isRailOrHighway =
        this.$store.state.Ticket.excavationDetails.isRailOrHighway;
      this.distance_from_road =
        this.$store.state.Ticket.excavationDetails.distance_from_road;
      this.direction_of_road =
        this.$store.state.Ticket.excavationDetails.direction_of_road;
      this.typeOfWorkTextBox =
        this.$store.state.Ticket.excavationDetails.typeOfWorkTextBox;
      this.excavationAreaTextBox =
        this.$store.state.Ticket.excavationDetails.excavationAreaTextBox;
      this.meansOfExcavationTextBox =
        this.$store.state.Ticket.excavationDetails.meansOfExcavationTextBox;
      this.distanceOffRoadTextBox =
        this.$store.state.Ticket.excavationDetails.distanceOffRoadTextBox;
      this.$nextTick(() => {
        if (this.$store.state.Ticket.excavationDetails.associateProject) {
          if (this.$refs.associateTicket) {
            this.$refs.associateTicket.selectedProjectId =
              this.$store.state.Ticket.excavationDetails.associateProject;
          }
          if (
            this.$refs.associateTicket &&
            this.$refs.associateTicket.selectedProjectId &&
            Object.keys(this.$refs.associateTicket.selectedProjectId).length !=
              0
          )
            this.$refs.associateTicket.associateTicketFlag = true;
          else if (this.$refs.associateTicket) {
            this.$refs.associateTicket.associateTicketFlag = false;
          }
        }
      });

      if (this.$store.state.Ticket.isEdit === "Digin") {
        this.digin_catv = this.$store.state.Ticket.excavationDetails.digin_catv;
        this.digin_elec = this.$store.state.Ticket.excavationDetails.digin_elec;
        this.digin_fiber =
          this.$store.state.Ticket.excavationDetails.digin_fiber;
        this.digin_gas = this.$store.state.Ticket.excavationDetails.digin_gas;
        this.digin_blowing =
          this.$store.state.Ticket.excavationDetails.digin_blowing;
        this.digin_phone =
          this.$store.state.Ticket.excavationDetails.digin_phone;
        this.digin_sewer =
          this.$store.state.Ticket.excavationDetails.digin_sewer;
        this.digin_water =
          this.$store.state.Ticket.excavationDetails.digin_water;
        this.digin_spill =
          this.$store.state.Ticket.excavationDetails.digin_spill;
        this.digin_other =
          this.$store.state.Ticket.excavationDetails.digin_other;
        await this.getMembersToAdd();
        this.membersToAddList = this.$store.state.Ticket.membersToAdd;
        this.selectedMembersToAdd = this.$store.state.Ticket.excavationDetails
          .membersToAdd
          ? this.$store.state.Ticket.excavationDetails.membersToAdd.split(",")
          : [];
      }
      if (this.$store.state.Ticket.isEdit === "UpdateTicket") {
        await this.getMembersToAdd();
        this.membersToAddList = this.$store.state.Ticket.membersToAdd;
        this.remarks = this.$store.state.Ticket.excavationDetails.remarks;
        this.reason = this.$store.state.Ticket.excavationDetails.reason;
        this.selectedMembersToAdd = this.$store.state.Ticket.excavationDetails
          .membersToAdd
          ? this.$store.state.Ticket.excavationDetails.membersToAdd.split(",")
          : [];
        this.direction_of_road =
          this.$store.state.Ticket.excavationDetails.direction_of_road;
      }
    }
  },
  methods: {
    convertTo24Hour(time) {
      // Split the time string into parts
      const [timePart, modifier] = time.split(" ");

      // Split the time into hours and minutes
      let [hours, minutes] = timePart.split(":");

      // Convert the hours to a number
      hours = parseInt(hours, 10);

      // If the time is in PM and not 12 PM, add 12 to the hour
      if (modifier === "PM" && hours !== 12) {
        hours += 12;
      }

      // If the time is in AM and is 12 AM, set the hour to 00
      if (modifier === "AM" && hours === 12) {
        hours = 0;
      }

      // Return the formatted 24-hour time
      return `${hours.toString().padStart(2, "0")}:${minutes}`;
    },
    updateTimeFormat() {
      if (!this.time) return;
      // Format the selected time to AM/PM
      const [hours, minutes] = this.time.split(":");
      const period = +hours < 12 ? "AM" : "PM";
      const formattedHours = +hours % 12 || 12;
      this.scheduleTime = `${formattedHours}:${minutes} ${period}`;
      // this.menu2 = false; // Close the menu after selection
    },
    async getLegalDate() {
      const legalDatesData = await this.$store.dispatch("legalDatesData", {
        center: this.ticketData.center,
      });
      this.picker = new Date(legalDatesData.workDate);
      if (this.$store.state.Ticket.isEdit !== "Digin") {
        // Get hours and minutes
        const hours = this.picker.getHours();
        const minutes = this.picker.getMinutes();

        // Format the hours and minutes to always have two digits
        const formattedHours = hours.toString().padStart(2, "0");
        const formattedMinutes = minutes.toString().padStart(2, "0");

        // Return the time in 24-hour format
        this.time = `${formattedHours}:${formattedMinutes}`;
        this.date = moment(legalDatesData.workDate).format("YYYY-MM-DD");
      }
      this.legalDates = legalDatesData;
    },
    continueButton() {
      this.work_date = new Date(this.date).toISOString();
      this.scheduleTime = new Date(this.date + " " + this.time);

      let excavationDetails = {
        work_date: this.scheduleTime,
        contact: this.contact,
        contact_phone: this.contact_phone,
        work_type: this.work_type,
        means_of_excavation: this.means_of_excavation,
        subdivision: this.subdivision,
        lot_number: this.lot_number,
        done_for: this.done_for,
        extent: this.extent,
        direction_to_work_area: this.direction_to_work_area,
        remarks: this.remarks,
        white_paint: this.white_paint,
        blasting: this.blasting,
        rr_or_highway: this.rr_or_highway,
        isRailOrHighway: this.isRailOrHighway,
        distance_from_road: this.distance_from_road,
        direction_of_road: this.direction_of_road,
        typeOfWorkTextBox: this.typeOfWorkTextBox,
        excavationAreaTextBox: this.excavationAreaTextBox,
        meansOfExcavationTextBox: this.meansOfExcavationTextBox,
        distanceOffRoadTextBox: this.distanceOffRoadTextBox,
        workTypeOptions: this.workTypeOptions,
        meansOfExcavationOptions: this.meansOfExcavationOptions,
        legalDates: this.legalDates,
      };
      if (
        this.$refs &&
        this.$refs.associateTicket &&
        this.$refs.associateTicket.selectedProjectId
      ) {
        excavationDetails.associateProject =
          this.$refs.associateTicket.selectedProjectId;
      }

      this.$store.state.Ticket.ticketData.streetLocation.cross1 = this.cross1;
      this.$store.state.Ticket.ticketData.streetLocation.cross2 = this.cross2;

      if (
        !this.$store.state.Ticket.ticketData.street &&
        this.$store.state.Ticket.ticketData.street != ""
      )
        this.$store.state.Ticket.ticketData.street = this.street;

      if (
        !this.$store.state.Ticket.ticketData.county &&
        this.$store.state.Ticket.ticketData.county != ""
      )
        this.$store.state.Ticket.ticketData.county = this.county;

      if (
        !this.$store.state.Ticket.ticketData.place &&
        this.$store.state.Ticket.ticketData.place != ""
      )
        this.$store.state.Ticket.ticketData.place = this.place;

      if (this.$store.state.Ticket.isEdit === "Digin") {
        excavationDetails.digin_catv = this.digin_catv;
        excavationDetails.digin_elec = this.digin_elec;
        excavationDetails.digin_fiber = this.digin_fiber;
        excavationDetails.digin_gas = this.digin_gas;
        excavationDetails.digin_blowing = this.digin_blowing;
        excavationDetails.digin_phone = this.digin_phone;
        excavationDetails.digin_sewer = this.digin_sewer;
        excavationDetails.digin_water = this.digin_water;
        excavationDetails.digin_spill = this.digin_spill;
        excavationDetails.digin_other = this.digin_other;
        excavationDetails.membersToAdd = this.selectedMembersToAdd.join(",");
      }
      if (this.$store.state.Ticket.isEdit === "UpdateTicket") {
        excavationDetails.remarks = this.remarks;
        excavationDetails.reason = this.reason;
        excavationDetails.membersToAdd = this.selectedMembersToAdd.join(",");
        excavationDetails.direction_of_road = this.direction_of_road;
      }
      this.$store.commit("setExcavationDetails", excavationDetails);
      this.$emit("excavationDetailsCompleted");
    },
    async getTypeOfWork() {
      const centerObject = {};
      centerObject["center"] = this.ticketData.center;
      const workTypeStatus = await this.$store.dispatch(
        "getTypeOfWork",
        centerObject
      );
      let workTypeArray = [];
      if (workTypeStatus.status !== "error") {
        workTypeStatus.map((ele) => {
          let tempStr = ele.description.replace(
            /(\w)(\w*)/g,
            function (g0, g1, g2) {
              return g1.toUpperCase() + g2.toLowerCase();
            }
          );
          workTypeArray.push(tempStr);
        });
        workTypeArray.push("Other");
        this.workTypeOptions = workTypeArray;
      }
    },
    async getMeansOfExcavation() {
      const centerObject = {};
      centerObject["center"] = this.ticketData.center;
      const meansOfExcavationStatus = await this.$store.dispatch(
        "getMeansOfExcavation",
        centerObject
      );
      let meansOfExcavationArray = [];
      if (meansOfExcavationStatus.status !== "error") {
        meansOfExcavationStatus.map((ele) => {
          let tempStr = ele.replace(/(\w)(\w*)/g, function (g0, g1, g2) {
            return g1.toUpperCase() + g2.toLowerCase();
          });
          meansOfExcavationArray.push(tempStr);
        });
        this.meansOfExcavationOptions = [...meansOfExcavationArray, "Other"];
      }
    },
    backButton() {
      this.$emit("excavationDetailsBack");
    },
    setStartDate(tempDate) {
      this.startDate = tempDate;
    },
    checkRR_or_highway() {
      if (this.isRailOrHighway === false) {
        this.rr_or_highway = "";
      }
    },
    disableWeekends(date) {
      const day = new Date(date).getDay();
      return !(day === 0 || day === 6);
    },
    allowedDates(date) {
      var dayOfWeek = moment(date).day();
      return !(dayOfWeek == 0 || dayOfWeek == 6);
    },
    enableTypeOfWorkTextBox() {
      this.typeOfWorkTextBox = true;
      this.work_type = "";
      this.$v.work_type.$reset();
    },
    disableTypeOfWorkTextBox() {
      this.typeOfWorkTextBox = false;
      this.work_type = "";
      this.$v.work_type.$reset();
    },
    emptyWorkType() {
      if (this.work_type === "Other") {
        this.typeOfWorkTextBox = true;
        this.work_type = "";
        this.$nextTick(() => {
          this.$v.work_type.$reset();
        });
      }
    },
    emptyExcavationArea() {
      if (this.extent === "Other") {
        this.excavationAreaTextBox = true;
        this.extent = "";
      }
    },
    emptyMeansOfExcavation() {
      if (this.means_of_excavation === "Other") {
        this.meansOfExcavationTextBox = true;
        this.means_of_excavation = "";
        this.$nextTick(() => {
          this.$v.means_of_excavation.$reset();
        });
      }
    },
    validateDistance() {
      if (this.distance_from_road < 0) {
        this.distance_from_road = 0;
      }
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },
    async getMembersToAdd() {
      let membersToAddArray = [];

      const membersToAddData = await this.$store.dispatch(
        "getMembersDetailsInCounty",
        {
          state: this.$store.state.Ticket.ticketData.state,
          county: this.$store.state.Ticket.ticketData.county,
          center: this.$store.state.Ticket.ticketData.center,
        }
      );
      if (membersToAddData.status !== "error") {
        membersToAddArray = membersToAddData.map(({ code, name }) => {
          return {
            mbcode: code,
            name,
            mbcode_name: `${code} - ${name}`,
          };
        });
        this.membersToAddList = membersToAddArray;
        await this.$store.commit("setMembersToAdd", this.membersToAddList);
      }
      //}
    },
    async getTimeFromWorkDate(date) {
      const workDate = await date;
      const timestamp = new Date(workDate);
      const hours = timestamp.getHours().toString().padStart(2, "0"); // Ensure 2-digit format
      const minutes = timestamp.getMinutes().toString().padStart(2, "0"); // Ensure 2-digit format
      const formattedTime = `${hours}:${minutes}`;

      return formattedTime;
    },
    enableExcavationAreaTextBox() {
      this.excavationAreaTextBox = true;
      this.extent = "";
    },
    disableExcavationAreaTextBox() {
      this.excavationAreaTextBox = false;
      this.extent = "";
    },
    enableMeansOfExcavationTextBox() {
      this.meansOfExcavationTextBox = true;
      this.means_of_excavation = "";
      this.$v.means_of_excavation.$reset();
    },
    disableMeansOfExcavationTextBox() {
      this.meansOfExcavationTextBox = false;
      this.means_of_excavation = "";
      this.$v.means_of_excavation.$reset();
    },
    enableDistanceOffRoadTextBox() {
      this.distanceOffRoadTextBox = true;
      this.distance_from_road = "";
    },
    disableDistanceOffRoadTextBox() {
      this.distanceOffRoadTextBox = false;
      this.distance_from_road = "";
    },
  },
};
</script>
<style lang="scss">
.select-container {
  .v-select__selections input {
    display: none;
  }

  .v-select__selections {
    display: grid !important;
  }
}

.v-select.v-input--dense .v-chip {
  margin: 8px 4px 0 4px;
}
</style>
